import { API } from "@project/shared";

export const CheckUserInfo = async (formData): Promise<any> => {
  return API.post("/company/validate", formData);
};

export const FetchCompanyInformation = async (id): Promise<any> => {
  return API.get(`/company/basic-info/${id}`);
};

export const SaveSignupInformation = async (formData) => {
  return API.post("/company/sign-up", formData);
};
export const ChangeUserEmail = async (formData): Promise<any> => {
  return API.post(`/change-email/${formData.id}`, { email: formData?.email });
};
export const GetCompanyID = async (id) => {
  return API.get(`/retrive/company/${id}`);
};
export const inviteMember = async (formData) => {
  return API.post(`/company/invite-members`, formData);
};
export const getMembersList = async (): Promise<any> => {
  return API.get(`/company/members`);
};
export const getInviteMembersList = async (): Promise<any> => {
  return API.get(`/company/invite-members`);
};
export const deleteMember = async (id) => {
  return API.delete(`/company/invite-members/${id}`);
};
export const deleteExistingMember = async (id) => {
  return API.delete(`/company/existing-member/${id}`);
};
export const FetchUserToken = async (id) => {
  return API.get(`/token/${id}`);
};

export const getUserInfo = async ({ queryKey }) => {
  const { data } = await API.post(`/get-user-details`, {
    uuid: queryKey[1],
    role: "member",
  });
  return data;
};

export const getUserDetails = async (uuid) => {
  const { data } = await API.post(`/get-user-details`, {
    uuid: uuid,
    role: "company",
  });
  return data;
};
